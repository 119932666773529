import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../../Objects/Table';
import { useHistory, withRouter } from 'react-router-dom';
import CSVDownload from '../../../Objects/CSVDownload';
import { textFilter, numberFilter, selectFilter } from 'react-bootstrap-table2-filter';


const Default = () => {

	const history = useHistory();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [csv1, setCSV1] = useState([]);
	const [csvName1, setCsvName1] = useState(null);
	const [selectIDs, setSelectIDs] = useState([]);
	const [showFilter, setShowFilter] = useState(sessionStorage.getItem("showFilter") === 'true' ? true : false);

	useEffect(() => {
	
		const getData = async () => {
			
			try{
				//件数の取得
				const ref = await API.Shipment.Scan();
				
				//データセット
				setData(ref.items);
				setView(ref.items);
			}
			catch(err){
				console.log(err);
			}

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};
	
	const handleCreateCSV1 = () => {

		//対象データの抽出
		const csvData = data.filter(x => selectIDs.includes(x.shipment_id));
		
		const dateObj      = new Date();
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const dateText     = String('' + year + month + day + hour + minute);

		//ファイル名
		setCsvName1('shipment_' + dateText + '.csv');

		//CSVデータのセット
		setCSV1(csvData);
	};
	
	const handleShowFilter = () => {
		setShowFilter(showFilter ? false : true);
		sessionStorage.setItem("showFilter", showFilter ? 'false' : 'true');
	};

	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/admin/shipment/' + row.shipment_id)}>
					詳細
				</Button>
			</div>
		);
	};
	
	const formatDate = (cell) => {
		if (cell) {
			// YYYYMMDD形式の文字列を年、月、日に分割
			const year = cell.substring(0, 4);
			const month = cell.substring(4, 6);
			const day = cell.substring(6, 8);
			
			// フォーマットして表示
			const dateText = `${year}年${month}月${day}日`;
			return (
				<div>
					{dateText}
				</div>
			);
		} else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};	

	const columns = [
		{
			dataField: 'dummy',
			isDummyField: true,
			text: '詳細',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		/*
		{
			dataField: 'shipment_id',
			text: '出荷情報ID',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: textFilter(),
		},
		*/
		{
			dataField: 'user_no',
			text: '得意先コード',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		/*
		{
			dataField: 'user_id',
			text: 'ユーザーID',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: textFilter(),
		},
		*/
		{
			dataField: 'order_date',
			text: '受注日',
			sort: true,
			formatter: formatDate,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
		},
		{
			dataField: 'delivery_kbn',
			text: '直送区分',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'item_id',
			text: 'JANコード',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'product_no',
			text: '品番',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'product_name',
			text: '品名',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'gousu',
			text: '号数',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'order_num',
			text: '発注数',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? numberFilter() : null,
		},
		{
			dataField: 'user_order_no',
			text: '客注番号',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'biko',
			text: '備考',
			sort: false,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'order_id',
			text: 'web受注番号',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'pre_order_flag',
			text: '注残',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? selectFilter({
				options: {
					0: '○',
					1: '×',
				}
			}) : null,
			formatter: (cell) => cell ? '×' : '○',
		},
		{
			dataField: 'pre_order_request',
			text: '注残管理',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? selectFilter({
				options: {
					0: '○',
					1: '×',
				}
			}) : null,
			formatter: (cell) => cell ? '×' : '○',
		},
		{
			dataField: 'shipping',
			text: '送料',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? numberFilter() : null,
		},
		{
			dataField: 'order_no',
			text: '受注No.',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'tracking_no',
			text: '問い合せNo.',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'shipment_num',
			text: '出荷数',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? numberFilter() : null,
		},
		{
			dataField: 'backorder_num',
			text: '注残数',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? numberFilter() : null,
		},
		{
			dataField: 'processing_date',
			text: '処理日',
			sort: true,
			formatter: formatDate,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
		},
		{
			dataField: 'due_date',
			text: '納期',
			sort: true,
			formatter: formatDate,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
		},
		{
			dataField: 'backorder_days',
			text: '注残日数',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? numberFilter() : null,
		},
		{
			dataField: 'shipping_date',
			text: '出荷日',
			sort: true,
			formatter: formatDate,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
		},
		{
			dataField: 'cancel_flag',
			text: 'キャンセル',
			sort: true,
			headerStyle: { whiteSpace: 'nowrap' },
			style: { whiteSpace: 'nowrap' },
			filter: showFilter ? selectFilter({
				options: {
					0: '未',
					1: '済',
				}
			}) : null,
			formatter: (cell) => cell ? '済' : '未',
		},
	];
	
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<Row>
					<Col sm={12}>
						<h4>出荷情報</h4>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={4}>
									<Button variant="danger" type="button" block="true" onClick={() => history.push('/admin/shipment/new/')} >新規登録</Button>
									　
									<Button variant="dark" type="button" block="true" onClick={() => history.push('/admin/csv-upload/shipment/')} >CSVアップロード</Button>
								</Col>
							</Row>

							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　<font color="white">{ view.length ? view.length.toLocaleString() : '0' }</font>　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block="true" >Search</Button>
								</Col>
								<Col sm={4}>
									<input type="checkbox" name="show_filter" value="1" checked={showFilter} onChange={handleShowFilter}/> フィルター表示
								</Col>
								<Col sm={2} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }} >
					<Col sm={12}>
						<Table keyField={'shipment_id'} data={view} columns={columns} checkbox={true} selectIDs={selectIDs} setSelectIDs={setSelectIDs} />
					</Col>
				</Row>
				<CSVDownload data={csv1} filename={csvName1} onClick={handleCreateCSV1}	>
					CSV出力
				</CSVDownload>
			</Container>
		);
	}
};

export default withRouter(Default);
