import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Breadcrumb,
} from 'react-bootstrap';
import Table from '../../Objects/Table';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText } from '../../Objects/Form';
import NotFound from '../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { order_id } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [item, setItem] = useState({});
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	useEffect(() => {
	
		const getData = async () => {
			
			const getItem = async (order_id) => {
				const ref = await API.Order.Query(order_id);
				//console.log(ref);
				setItem(ref.items[0]);
			};
			
			await getItem(order_id);
			
			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	useEffect(() => {

		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};

	const dateShaping = (datetime) => {
		const dateObj      = new Date(Number(datetime * 1000));
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour         = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute       = ('00' + dateObj.getMinutes().toString()).slice(-2);
		return(year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分');
	
	};
	
	const formatItemLink = (cell, row) => {
		return (
			<div>
				<a href={'/item/' + row.item_id}>{cell}</a>
			</div>
		);
	};
	
	const formatPrice = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()} 円
			</div>
		);
	};
	
	const formatPiece = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()} 個
			</div>
		);
	};
	
	const columns = [
		{
			dataField: 'item_id',
			text: 'JANコード',
			sort: true,
			formatter: formatItemLink,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'product_no',
			text: '品番',
			sort: true,
			formatter: formatItemLink,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'product_name',
			text: '品名',
			sort: true,
			formatter: formatItemLink,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'gousu',
			text: '号数',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'pre_order_flag',
			text: '注残',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: (cell) => cell ? '×' : '○',
		},
		{
			dataField: 'pre_order_request',
			text: '注残管理',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: (cell) => cell ? '×' : '○',
		},
		{
			dataField: 'price',
			text: 'ﾒｰｶｰ希望本体価格',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
/*
		{
			dataField: 'unit_price',
			text: '一般卸価格',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
*/
		{
			dataField: 'piece',
			text: '個数',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPiece,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'sub_price',
			text: '合計金額',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
	];
	
	const mobileColumns = [
		{
			dataField: 'item_id',
			text: '詳細',
			sort: true,
			formatter: (cell, row) => {
				return (
					<table width="100%">
						<tbody>
						<tr>
							<td>JAN</td>
							<td><a href={'/item/' + row.item_id}>{row.item_id}</a></td>
						</tr>
						<tr>
							<td>在庫</td>
							<td>{row.stock_flag}</td>
						</tr>
						<tr>
							<td>品番</td>
							<td>{row.product_no}</td>
						</tr>
						<tr>
							<td>品名</td>
							<td>{row.product_name}</td>
						</tr>
						<tr>
							<td>号数</td>
							<td>{row.gousu}</td>
						</tr>
						<tr>
							<td>注残</td>
							<td>{row.pre_order_flag ? '×' : '○'}</td>
						</tr>
						<tr>
							<td>注残管理</td>
							<td>{row.pre_order_request ? '×' : '○'}</td>
						</tr>
						<tr>
							<td>価格</td>
							<td>{Number(row.price ?? 0).toLocaleString()} 円</td>
						</tr>
						<tr>
							<td>個数</td>
							<td>{Number(row.piece ?? 0).toLocaleString()} 個</td>
						</tr>
						<tr>
							<td>合計</td>
							<td>{Number(row.price * row.piece ?? 0).toLocaleString()} 円</td>
						</tr>
						</tbody>
					</table>
				);
			},
			headerStyle:  { display: 'none' },
		},
	];

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(Object.keys(item).length === 0){
		return (
			<NotFound />
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>

				<Breadcrumb>
					<Breadcrumb.Item href="/order-history/">発注履歴</Breadcrumb.Item>
					<Breadcrumb.Item active>詳細</Breadcrumb.Item>
				</Breadcrumb>

				<Form name="form">

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'発注日時'} name={'date_add'} value={dateShaping(item.date_add)} readOnly={true} />
					<FormText label={'ステータス'} name={'status'} value={item.status} readOnly={true} />
					<hr/>
					<FormText label={'Eメール'} name={'email'} type={'email'} value={item.email} readOnly={true} />
					<FormText label={'得意先コード'} name={'user_no'} value={item.user_no} readOnly={true} />
					<FormText label={'お名前'} name={'user_name'} value={item.user_name} readOnly={true} />
					<FormText label={'郵便番号'} name={'zip'} value={item.zip} readOnly={true} />
					<FormText label={'住所'} name={'addr'} value={item.addr} readOnly={true} />
					<FormText label={'会社名'} name={'company'} value={item.company} readOnly={true} />
					<FormText label={'担当者名'} name={'person'} value={item.person} readOnly={true} />
					<FormText label={'電話番号'} name={'tel'} type={'tel'} value={item.tel} readOnly={true} />
					<FormText label={'携帯電話'} name={'mobile'} type={'tel'} value={item.mobile} readOnly={true} />
					<FormText label={'FAX番号'} name={'fax'} type={'tel'} value={item.fax} readOnly={true} />
					<hr/>
					<Row>
						<Col sm={12}>
							<Table keyField={'item_id'} data={item.items} columns={isMobile ? mobileColumns : columns} />
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col sm={12}>
							<table className="table table-bordered">
								<tbody>
								<tr>
									<td>合計点数</td>
									<td className="text-right">{ Number(item?.items?.length ?? 0).toLocaleString() } 点</td>
								</tr>
								<tr>
									<td>送料</td>
									<td className="text-right">{ Number(item?.shipping ?? 0).toLocaleString() } 円</td>
								</tr>
								<tr>
									<td>合計金額</td>
									<td className="text-right">{ Number(item?.total_price ?? 0).toLocaleString() } 円</td>
								</tr>
								</tbody>
							</table>
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col sm={6}>
							<p><big>連絡欄</big></p>
						</Col>
					</Row>
					<textarea name="note" className="form-control" readOnly={true}>
						{item.note}
					</textarea>
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col>
						        <Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} >もどる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		);
	}
};

export default withRouter(Default);
