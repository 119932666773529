import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { useHistory, withRouter } from 'react-router-dom';
import CSVDownload from '../../Objects/CSVDownload';


const Default = () => {

	const history = useHistory();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [csv, setCSV] = useState([]);
	const [csvName, setCsvName] = useState(null);
	const [selectIDs, setSelectIDs] = useState([]);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	console.log(isMobile, window.innerWidth);

	useEffect(() => {
	
		const getData = async () => {
			
			try{
				//件数の取得
				const ref = await API.Order.Query();
				
				//データセット
				setData(ref.items);
				setView(ref.items);
			}
			catch(err){
				console.log(err);
			}

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	useEffect(() => {

		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};
	
	const handleCreateCSV = () => {
	
		const getDateText = (date_time) => {
			const dateObj      = new Date(Number(date_time * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分');
			return(dateText);
		};
	
		const csvData = [];
		
		//対象データの抽出
		for(const x of data){
			if(selectIDs.includes(x.order_id)){
				for(const item of x.items){
					const params = {
						order_id : x.order_id,
						user_id : x.user_id,
						email : x.email,
						user_no : x.user_no,
						user_name : x.user_name,
						zip : x.zip,
						addr : x.addr,
						company : x.company,
						person : x.person,
						tel : x.tel,
						fax : x.fax,
						mobile : x.mobile,
						item_id : item.item_id,
						product_no : item.product_no,
						product_name : item.product_name,
						gousu : item.gousu,
						price : Number(item?.price ?? 0),
						unit_price : Number(item?.unit_price ?? 0),
						piece : Number(item?.minimum_unit ?? 1),
						minimum_unit : item.minimum_unit,
						category1_name : item.category1_name,
						category1_code : item.category1_code,
						category2_name : item.category2_name,
						category2_code : item.category2_code,
						genre_name : item.genre_name,
						genre_code : item.genre_code,
						catalog_no : item.catalog_no,
						keyword : item.keyword,
						sort : item.sort,
						boxed_num : item.boxed_num,
						url : item.url,
						pre_order_flag : item.pre_order_flag,
						pre_order_request : item?.pre_order_request ?? null,
						stock_flag : item.stock_flag,
						shipping : item.shipping,
						total_price : x.total_price,
						note : x.note,
						status : x.status,
						message : item.message,
						date_add : getDateText(x.date_add),
						date_edit : getDateText(x.date_edit),
					};
					csvData.push(params);
				}
			}
		}
		
		const dateObj      = new Date();
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const dateText     = String('' + year + month + day + hour + minute);

		//ファイル名
		setCsvName('order_' + dateText + '.csv');

		//CSVデータのセット
		setCSV(csvData);
	};

	const formatButton1 = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/order-history/' + row.order_id)}>
					詳細
				</Button>
			</div>
		);
	};
	
	const formatButton2 = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/shipment/' + row.order_id)}>
					出荷状況
				</Button>
			</div>
		);
	};
	
	const formatPrice = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()} 円
			</div>
		);
	};
	
	const formatItem = (cell, row) => {
		if(cell.length === 1){
			return (
				<div>
					{cell[0].product_name}
				</div>
			);
		}
		else{
			return (
				<div>
					{cell[0].product_name}、他{(cell.length - 1)}点
				</div>
			);
		}
	};
	
	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分');
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};

	const formatItemMobile = (cell) => {
		if(cell.length === 1){
			return cell[0].product_name;
		}
		return cell[0].product_name + '、他' + (cell.length - 1) + '点';
	};
	
	const formatDateMobile = (cell) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
			const dateText     = (year + '-' + month + '-' + day + ' ' + hour + ':' + minute);
			return dateText;
		}
		return '';
	};
	
	const columns = [
		{
			dataField: 'dummy',
			isDummyField: true,
			text: '詳細',
			sort: false,
			formatter: formatButton1,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'shipment',
			isDummyField: true,
			text: '出荷状況',
			sort: false,
			formatter: formatButton2,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'date_add',
			text: '発注日時',
			sort: true,
			formatter: formatDate,
		},
		{
			dataField: 'total_price',
			text: '発注金額',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
		},
		{
			dataField: 'items',
			text: '発注品',
			sort: true,
			formatter: formatItem,
		},
		{
			dataField: 'note',
			text: '連絡事項',
			sort: true,
		},
		{
			dataField: 'status',
			text: '受注ステータス',
			sort: true,
		},
	];
	
	const mobileColumns = [
		{
			dataField: 'order_id',
			text: '詳細',
			sort: true,
			formatter: (cell, row) => {
				return (
					<table width="100%">
						<tbody>
						<tr>
							<td>日時</td>
							<td>{formatDateMobile(row.date_add)}</td>
						</tr>
						<tr>
							<td>金額</td>
							<td>{Number(row.total_price ?? 0).toLocaleString()} 円</td>
						</tr>
						<tr>
							<td>品名</td>
							<td>{formatItemMobile(row.items)}</td>
						</tr>
						<tr>
							<td>連絡</td>
							<td>{row.note}</td>
						</tr>
						<tr>
							<td>状況</td>
							<td>{row.status}</td>
						</tr>
						<tr>
							<td colSpan="2">
								<Button variant="dark" type="button" onClick={() => history.push('/order-history/' + row.order_id)}>
									詳細
								</Button>
							</td>
						</tr>
						</tbody>
					</table>
				);
			},
			headerStyle:  { display: 'none' },
		},
	];

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				
				<Row>
					<Col sm={12}>
						<h4>発注履歴</h4>
					</Col>
				</Row>
				<Row style={{display : isMobile ? 'none' : 'block'}}>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　<font color="white">{ view.length ? view.length.toLocaleString() : '0' }</font>　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block="true" >Search</Button>
								</Col>
								<Col sm={6} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }} >
					<Col sm={12}>
						<Table keyField={'order_id'} data={view} columns={isMobile ? mobileColumns : columns} checkbox={isMobile ? false : true} selectIDs={selectIDs} setSelectIDs={setSelectIDs} sort={ { field: 'date_add', order: 'desc'} } />
					</Col>
				</Row>
				{!isMobile &&
					(
						<CSVDownload data={csv}	filename={csvName} onClick={handleCreateCSV} >
							CSV出力
						</CSVDownload>
					)
				}
			</Container>
		);
	}
};

export default withRouter(Default);
