import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Button,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';
import { Scanner } from '../../Objects/Scanner';

const Default = () => {

	const [load, setLoad] = useState(true);
	const [alert, setAlert] = useState([]);

	useEffect(() => {
		
		//表示開始
		setLoad(false);

		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	const handleDetected = async (item_id) => {

		if(!item_id){
			return;
		}

		let item = null;

		//商品情報取得
		try{

			const refItem = await API.Item.Get(item_id);

			item = refItem?.item ?? null;
		}
		catch(err){
			console.log(err);
		}

		//カートに追加
		try {

			if(!item || Object.keys(item).length === 0){
				throw new Error('商品が見つかりませんでした。「' + item_id + '」');
			}

			const params ={
				item_id : item.item_id,
				product_no : item.product_no,
				product_name : item.product_name,
				gousu : item.gousu,
				price : Number(item?.price ?? 0),
				unit_price : Number(item?.unit_price ?? 0),
				piece : Number(item?.minimum_unit ?? 1),
				minimum_unit : item.minimum_unit,
				category1_name : item.category1_name,
				category1_code : item.category1_code,
				category2_name : item.category2_name,
				category2_code : item.category2_code,
				genre_name : item.genre_name,
				genre_code : item.genre_code,
				catalog_no : item.catalog_no,
				keyword : item.keyword,
				sort : item.sort,
				boxed_num : item.boxed_num,
				url : item.url,
				pre_order_flag : item.pre_order_flag,
				pre_order_request : item?.pre_order_flag ? 1 : 0,
				stock_flag : item.stock_flag,
				message : '',
			};

			//削除
			const ref = await API.Cart.Add(params);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			setAlert(['商品をカートに入れました。']);
			
		}
		catch(err){
			setAlert([err.message]);
		}

	};

	const handleClose = () => {
		setAlert([]);
	};

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />

				<Row>
					<Col sm={12}>
						<h4>バーコード発注</h4>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Button variant="primary" onClick={ () => { window.location.href = '/cart/'; } }>
									商品カートに移動する
						</Button>
					</Col>
				</Row>

				<Row style={{ marginTop: 20}}>
					<Col sm={12}>
						<section className="section-wrapper">
							<div className="section-title">
								<h1 className="section-title-text">
									<Scanner onDetected={handleDetected} />
								</h1>
							</div>
						</section>
					</Col>
				</Row>

			</Container>
		);
	}
};

export default withRouter(Default);
